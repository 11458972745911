import React from "react"
import Layout from "../components/Layout"
import Carouselcomp from "../components/Carouselcomp"
import { Row, Col, Container } from "react-bootstrap"
import { graphql } from "gatsby"
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe"
import { FaCalendarDay } from "@react-icons/all-files/fa/FaCalendarDay"
import { GiPencilBrush } from "@react-icons/all-files/gi/GiPencilBrush"
import { AiFillBulb } from "@react-icons/all-files/ai/AiFillBulb"
import { AiOutlineBulb } from "@react-icons/all-files/ai/AiOutlineBulb"
import Headmeta from "../components/Headmeta"
import useSitedata from "../components/useSitedata"
import ShareComp from "../components/ShareComp"

export default function StoreDetails({ data }) {
  const {
    title,
    slug,
    month,
    year,
    surface,
    original,
    print,
    medium,
    totalImages,
  } = data.markdownRemark.frontmatter

  // console.log('logggin title from template',title)
  const artworksPageName = useSitedata("artworksPageName")
  const baseUrl = useSitedata("url")
  const url = `${baseUrl}/${artworksPageName}/${slug}`

  // console.log(data)
  let imagesFiltered = []
  for (let i = 1; i <= totalImages; i++) {
    imagesFiltered.push(
      eval(
        `data.markdownRemark.frontmatter.image${i}.childImageSharp.gatsbyImageData`
      )
    )
  }

  const mdate = new Date(year, month - 1, 1)
  const monthName = mdate.toLocaleString("default", { month: "long" })
  // console.log(year,month)
  const projectDate = `${monthName} ${year}`

  let availability = ""
  if (print === 1 && original === 1) {
    availability = "Original & Print available"
  } else if (print === 1 && original === 0) {
    availability = "Print available"
  } else if (print === 0 && original === 1) {
    availability = "Original available"
  } else {
    availability = "For reference only"
  }

  const detailsText2 = availability

  let detailsText1 = ""
  if (surface === null) {
    detailsText1 = medium
  } else {
    detailsText1 = `${medium} on ${surface}`
  }

  return (
    <Layout>
      <Headmeta
        importedIn={`${title} | ${detailsText1} | ${
          artworksPageName[0].toUpperCase() + artworksPageName.slice(1)
        }`}
        importedUrl={`${artworksPageName}/${slug}`}
      />

      <Container>
        <Row className="pt-5">
          <Col md={8}>
            <Carouselcomp imagesAll={imagesFiltered} />
          </Col>

          <Col>
            <h2 className="pt-5">{title}</h2>
            <p className="text-muted">
              <FaGlobe />{" "}
              <span style={{ color: "rgb(0,124,165)" }}>Coordinarts:</span>{" "}
              <span style={{ color: "rgb(213,66,131)" }}>{slug}</span> <br />
              <FaCalendarDay /> {projectDate}
              {/* <span style={{ color: "rgb(0,200,200)" }}> {projectDate}</span>{" "} */}
              <br />
              <GiPencilBrush /> {detailsText1}
              <br />
              {availability === "For reference only" ? (
                <span style={{ color: "rgb(190,190,190)", fontSize: "1.2em" }}>
                  <AiOutlineBulb />
                </span>
              ) : (
                <span style={{ color: "rgb(50,150,50)", fontSize: "1.2em" }}>
                  <AiFillBulb />
                </span>
              )}
              {detailsText2}
            </p>
            <ShareComp url={url} headColor="rgb(7,190,184)" />

            {/* <p className="text-muted">{`Coordinarts: ${slug}`}</p>
            <p className="text-muted">{projectDate}</p>
            <p className="text-muted">{detailsText1}</p>
            <p className="text-muted">{detailsText2}</p> */}
          </Col>
        </Row>
      </Container>
      {/* <Carouselcomp imagesAll={imagesFiltered} />
      <Container className="text-center p-5">
        <Row>
          <Col>
            <p>{shortBio}</p>
          </Col>
        </Row>
      </Container> */}

      {/* <div className={ styles.details }>
                <div className={ styles.featured}>
                    <GatsbyImage image={ image.childImageSharp.gatsbyImageData } alt='banner' />
                </div>
                <h2>{ title }</h2>
                <h3>{ shortBio }</h3>
                <p>{ price }</p>
          </div> */}
    </Layout>
  )
}

export const query = graphql`
  query StoreDetails($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        slug
        month
        year
        surface
        original
        print
        medium
        totalImages
        image {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image1 {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image2 {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
      }
    }
  }
`
